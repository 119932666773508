.prBreadcrumbContainer {
  /* padding: 5px 0px 0px 0px; */
  /* width: 100%; */
  padding: 10px 0px 0px 0px;
  margin-bottom: -5px;
}

.prDetailHeaderContainer {
  /* padding: 0px 0 20px 20px; */
  padding: 16px 0 10px 0px;
  width: 100%;
  background: linear-gradient(to right, #05296b,#79291b,#e78709);
  /* border-bottom: 1px solid lightgray; */
  position: relative;
}

.prDetailHeaderLabel {
  padding: 0px 0 50px 26px;
  color: white;
  font-size: 35px;
  /* font-weight: bold; */
  font-family: Inter;
  width: 95%;
  inline-size: 960px;
}

.prDetailHeaderContent {
  padding: 0px 0 5px 30px;
  color: white;
  font-size: 16px;
  font-family: Lato;
  width: 80%;
}

.prDetailHeaderText {
  /* padding: 0px 0 5px 30px; */
  color: #25B39A;
  font-size: 16px;
  width: 100%;
  /* text-decoration: none; */
}

.prDetailExternalLink {
  color: #25B39A;
  /* color: white; */
  /* display: inline; */
}

.prDetailExternalLink:hover {
  color: #00f5ca;
}

.prDetailHeaderLink {
  color: #25B39A;
  /* color: white; */
  /* text-decoration: none; */
}

.prDetailHeaderLink:hover {
  color: #00f5ca;
}

.prIcon {
  width: 130px;
  position: absolute;
  right: 50px;
  top: 30px;
  border: 1px solid #FFBF17;
  padding: 10px;
  height: 106px;
}

.prTypeButton {
  width: 10%;
  font-size: 15px;
  background-color: white;
  margin-left: 88%;
  margin-right: 1%;
  margin-bottom: -80px;
  border: 2px solid gold;
  border-radius: 20px 20px 20px 20px;
  /* margin-top: 10px; */
}

.prspace {
  border-bottom: 1px solid #BFD3E1;
  margin: 0 -9999rem;
}

.prAboutContentContainer {
  /* padding: 20px 0px 10px 0px; */
  padding: 10px 10px 10px 10px;
  border: 1px solid #BFD3E1;
  border-top: none;
  border-bottom: none;
  /* border: 1px solid lightgray; */
  /* border-bottom: 1px solid gray; */
  width: 1220px;
  margin-left: -10px;
}

.prAboutResourceContainer {
  padding: 5px 0 10px 20px;
  background-color: rgb(248, 248, 248);
  font-family: Lato;
  /* border-top: 1px solid gray; */
  /* border-bottom: 1px solid gray; */
}

.prAboutResourceLabel {
  padding: 5px 0 22px 10px;
  /* color: rgb(180, 180, 180); */
  color: #99AFBE;
  font-size: 26px;
  font-family: Inter;
  /* font-weight: bold; */
  text-transform: uppercase;
  /* border-bottom: 2px solid rgb(163, 163, 163); */
  border-bottom: 3px solid #BFD3E1;
  inline-size: 1160px;
}

.prAboutResourceArrowSymbol {
  height: 12px;
  width: 25px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  /* border-bottom: 12px solid #7397b0; */
  margin-left: 317px;
  margin-top: -25px;
}

.prAboutResourceArrowSymbol2 {
  color: #7397b0;
  display:inline-block;
  transform: scale(1.1,0.6);
}

.accordion-header-pr {
  margin: 0 0 0 0px;
  /* padding: 5px 0 22px 10px; */
  font-size: 26px;
  font-family: Inter;
  font-weight: 400;
  text-transform: uppercase;
  inline-size: 1160px;
}
.accordion-button-ccdc-pr {
  padding: 8px 0 14px 10px;
  color: #99AFBE;
  font-size: 26px;
  font-family: Inter;
  text-transform: uppercase;
  border-bottom: 3px solid #BFD3E1;
  inline-size: 1160px;
  font-weight: 400;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.accordion-button-ccdc-pr:focus {
  outline: 0;
  border: 0;
  box-shadow: none;
}

.accordion-button-pr:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.accordion-item-first-pr .accordion-button-pr:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.accordion-button-pr:not(.collapsed)::after {
  background-image: none;
  transform: rotate(-180deg);
}

.accordion-button-pr::after {
  flex-shrink: 1;
  margin-left: 20px;
  /* width: 1.2rem; */
  /* height: 1.2rem; */
  width: auto;
  height: auto;
  display: inline-block;
  vertical-align: .08em;
  content: "";
  border-top: .6em solid;
  border-right: .5em solid transparent;
  border-bottom: 0;
  border-left: .5em solid transparent;
  /* font-size: 1.5rem; */
  background-image: none;
  transition: transform .2s ease-in-out;
  color: #7397b0;
}

.prAboutResourceContent {
  padding: 30px 0px 30px 10px;
  /* border-bottom: 2px solid rgb(163, 163, 163); */
  font-family: Lato;
  border-bottom: 3px solid #BFD3E1;
  inline-size: 1160px;
}

.prResourceToolsContainer {
  padding: 0px 30px 10px 20px;
  width: 48%;
  height: 200px;
  border-right: 1px solid #BFD3E1;
  /* display: flex; */
  /* display: inline-block; */
  /* position: relative; */
  display: inline-table;
}

.prCoreDataLabel {
  padding: 20px 0 10px 0px;
  color: #004187;
  font-size: 23px;
  font-weight: 900;
  font-family: Lato;
  border-bottom: 1px solid #004187;
  width: 100%;
}

.prDataAccessContainer {
  padding: 0px 0 10px 30px;
  width: 48%;
  display: inline-table;
  /* max-inline-size: 500px; */
  word-break: break-all;
}

.prAdditionalDataLabel {
  padding: 20px 0 10px 0px;
  color: #004187;
  font-size: 23px;
  font-weight: 900;
  font-family: Lato;
  border-bottom: 1px solid #004187;
  /* width: 95%; */
}

.prDataElementLabel {
  padding: 20px 0 0px 0px;
  /* color: #2dc799; */
  color: #25b39a;
  font-size: 16px;
  font-family: Lato;
  text-transform: uppercase;
  display: inline-table;
  width: 50%;
}

.prDataElementContent {
  /* padding: 30px 0 10px 0px; */
  /* color: #25b39a; */
  font-size: 16px;
  font-family: Lato;
  display: inline-table;
}

.summaryDataElementLabel {
  padding: 20px 20px 10px 0px;
  display: inline;
  /* color: #2dc799; */
  color: #25b39a;
  font-size: 16px;
  font-family: Lato;
  text-transform: uppercase;
}

.summaryDataElementContent {
  /* font-size: 16px; */
  font-family: Lato;
  /* text-transform: uppercase; */
  /* height: 100px; */
  display: inline;
  max-height: 200px;
  overflow: auto;
}

.summaryDataElementPublished {
  font-family: Lato;
  display: inline;
  max-height: 200px;
  /* overflow: auto; */
  word-break: break-all;
  /* inline-size: 80%; */
  /* display: inline; */
}

.summaryPublishedLinkBreak {
  margin-left: -3px;
  /* overflow: auto; */
  display: inline;
}

.badge {
  display: inline-block;
  /* min-width: 1em; */
  padding: 0.4em;
  width: 32px;
  border-radius: 50%;
  border: 2px solid white;
  font-size: 15px;
  text-align: center;
  background: #004187;
  color: white;
  position: absolute;
  top: 20px;
  right: 35px;
}

.badgeCount {
  display: inline-block;
  width: 10px;
  font-size: 15px;
  color: black;
  position: absolute;
  top: 10px;
  right: 25px;
}
