.pagination-ccdc {
  margin: 0;
}
.pagination-ccdc .page-item .page-link{
  padding: 0.275rem 0;
  text-align: center;
  width: 33px;
  border-top: 1px solid #A0B3C8;
  border-bottom: 1px solid #A0B3C8;
  border-color: #A0B3C8;
  margin-left: 0;
}
.pagination-ccdc .page-item .page-link:focus{
  outline: none;
  box-shadow: none;
}
.bspage-link.page-item.active .page-link{
  color: inherit;
  border-left: 1px solid #A0B3C8;
  border-right: 1px solid #A0B3C8;
  background-color: #BFE3FF;
  font-size: 15px;
}
.pagination-ccdc li:nth-child(2).active .page-link{
  border-left: 0;
}
.pagination-ccdc li:nth-last-child(2).active .page-link{
  border-right: 0;
}
.bspage-link.page-item .page-link{
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #F6FBFF;
  color: #004187;
  border-left: 0;
  border-right: 0;
  font-weight: bold;
  font-family: Lato;
  font-size: 15px;
  margin: 0px;
}
.bspage-link.page-item .page-link:hover {
  background-color: #c6d2db;
  color: #004187;
}
.bspage-link-prev .page-link{
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #F6FBFF;
  color: #004187;
  border-left: 1px solid #A0B3C8;
  border-right: 1px solid #A0B3C8;
  font-weight: bold;
  font-family: Lato;
  font-size: 15px;
}
.bspage-link-prev.page-item .page-link{
  border-radius: 0;
}
.bspage-link-next .page-link{
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #F6FBFF;
  color: #004187;
  border-left: 1px solid #A0B3C8;
  border-right: 1px solid #A0B3C8;
  font-weight: bold;
  font-family: Lato;
  font-size: 15px;
}
.bspage-link-next.page-item .page-link{
  border-radius: 0;
}
.dropdown-item.active {
  /* border: 1px solid #7CACCF; */
  border-top: 1px solid #7CACCF;
  border-bottom: 1px solid #7CACCF;
  background-color: #FFFFFF;
}

.dropdown-item.active-top {
  border-bottom: 1px solid #7CACCF;
  background-color: #FFFFFF;
}

.dropdown-item.active-bottom {
  border-top: 1px solid #7CACCF;
  background-color: #FFFFFF;
}

.dropdown-item:active {
  background-color: #0776c6;
}

.dropdown-menus {
  border: 1px solid #7CACCF;
}