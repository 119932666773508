.searchBarContainer {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.searchBarArea {
  margin: 0 auto;
  width: 1200px;
  display: grid;
  position: relative;
}

.searchBarLabel {
  padding: 20px 0 10px 0;
  color: #004187;
  font-size: 42px;
  line-height: 34px;
  font-weight: bold;
  font-family: Inter;
  width: 100%;
  display: flex;
}

.searchTooltip {
  margin-left: 15px;
  line-height: 24px;
}

.searchTooltipButton {
  line-height: 0;
  padding: 0;
  margin-left: -5px;
  position: absolute;
  border: 0;
  color: #6c757d;
  font-size: 22px;
  border-radius: 10px;
}

.searchTooltipBox {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  margin-left: 10px;
  z-index: 100;
  box-shadow: rgba(7, 54, 139, 0.7) 0px 0px 0px 2px, rgba(7, 54, 139, 0.7) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.searchBoxContainer {
  width: 100%;
  display: flex;
}

.searchCatalogContainer {
  width: 100%;
}

.searchArea {
  margin: 0 auto;
  width: 1200px;
  display: flex;
}

.searchFiltersContainer {
  width: 20%;
  border-left: 1px solid #e0e4e7;
  border-right: 1px solid #e0e4e7;
  padding: 0 0 80px 0;
}

.searchContentContainer {
  width: 80%;
  border-right: 1px solid #e0e4e7;
  padding: 5px 10px;
}

.searchContentHeader {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #a1c0d1;
}

.contentSwitchArea {
  width: 50%;
}

.exportArea {
  width: 50%;
}

.searchDisplayOptionsRow {
  width: 100%;
  display: flex;
  padding: 10px 0;
  border-bottom: 3px solid #c3d5e0;
  position: relative;
}

.searchDisplayOptionsRowTable {
  width: 100%;
  display: flex;
  padding: 10px 0 48px 0;
  border-bottom: 3px solid #c3d5e0;
  position: relative;
}

.searchSortingArea {
  width: 40%;
  display: flex;
}

.contentPagingArea {
  position: absolute;
  right: 0;
}

.space50 {
  padding-top: 50px;
}

.searchContent {
  width: 100%;
}

.searchContentFooter {
  width: 100%;
  padding: 10px 0;
  border-top: 3px solid #c3d5e0;
}