body {
  margin: 0;
  font-family:
    'Lato',
    'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

#root {
  width: 100%;
  margin: 0 auto;
}

nav {
  padding: 20px 0;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.custom-popover.popover {
  border-radius: 6px;
  font-size: 12px;
  word-wrap: normal;
  z-index: 99;
}

.popover-body {
  padding: 10px;
}