.wrapper-dropdown {
  position: relative;
  width: 200px;
  padding: 1px 10px;
  box-shadow: 0 1px 1px rgba(50,50,50,0.1);
  cursor: pointer;
  outline: none;
  font-weight: 400;
  font-family: Lato;
  font-size: 14px;
  color: #004187;
  border-radius: 0;
  border: 1px solid #99a9b1;
  background-color: #e8f1f8;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='rgba(75,108,134,1)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.wrapper-dropdown .dropdownLabel {
  line-height: 34px;
}

.wrapper-dropdown .dropdown {
  z-index: 1;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    background: white;
    border-radius: inherit;
    border: 1px solid rgba(0,0,0,0.17);
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    font-weight: normal;
    transition: all 0.1s ease-in;
    list-style: none;
    opacity: 0;
    pointer-events: none;
    margin: 0;
    padding: 0;
}

.wrapper-dropdown .dropdown li span {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #8aa8bd;
    border-bottom: 1px solid #e6e8ea;
    box-shadow: inset 0 1px 0 rgba(255,255,255,1);
    transition: all 0.1s ease-out;
}

.wrapper-dropdown .dropdown li i {
    float: right;
    color: inherit;
}

.wrapper-dropdown .dropdown li:first-of-type span {
    border-radius: 7px 7px 0 0;
}

.wrapper-dropdown .dropdown li:last-of-type span {
    border-radius: 0 0 7px 7px;
    border: none;
}

/* Hover state */

.wrapper-dropdown .dropdown li:hover span {
    background: #f3f8f8;
}

.wrapper-dropdown.active .dropdown {
  opacity: 1;
  pointer-events: auto;
}

.wrapper-dropdown:hover {
  background-color: #c6d2db;
}
