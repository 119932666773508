
.datasetBreadcrumbContainer {
  padding: 10px 0px 0px 0px;
  margin-bottom: -5px;
}

/* Style the list */
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  /* background-color: #eee; */
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 8px;
  /* color: goldenrod; */
  color: #3d4551;
  content: ">";
  /* content: "/\00a0"; */
  font-size: 16px;
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  /* color: goldenrod; */
  color: #1783d8;
  text-decoration: none;
  font-size: 14px;
  font-family: Lato;
  text-decoration: underline;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.datasetDetailHeaderContainer {
  /* padding: 0px 0 20px 20px; */
  padding: 16px 0 10px 0px;
  width: 100%;
  /* background: linear-gradient(to right, #3060b8,#05296b); */
  background: linear-gradient(.25turn, #0c3561, 66%, #1b83d2);
  /* border-bottom: 1px solid lightgray; */
  position: relative;
}

.datasetDetailHeaderLabel {
  padding: 0px 0px 60px 26px;
  color: white;
  font-size: 34px;
  font-weight: medium;
  font-family: Inter;
  width: 95%;
  inline-size: 960px;
  min-height: 120px;
}

.datasetDetailHeaderLabelLong {
  padding: 0px 0px 40px 26px;
  color: white;
  font-size: 32px;
  font-weight: 500;
  font-family: Inter;
  width: 95%;
  inline-size: 960px;
  line-height: 36px;
  min-height: 120px;
}

.datasetDetailHeaderLabel2 {
  padding: 0px 0px 40px 26px;
  color: white;
  font-size: 28px;
  font-weight: 500;
  font-family: Inter;
  width: 95%;
  inline-size: 960px;
  line-height: 36px;
  min-height: 120px;
}

.datasetDetailHeaderLabel3 {
  padding: 0px 0px 46px 26px;
  color: white;
  font-size: 24px;
  font-weight: 500;
  font-family: Inter;
  width: 95%;
  inline-size: 960px;
  line-height: 33px;
  min-height: 120px;
}

.datasetDetailHeaderLabel4 {
  padding: 0px 0px 40px 26px;
  color: white;
  font-size: 22px;
  font-weight: 500;
  font-family: Inter;
  width: 95%;
  inline-size: 960px;
  line-height: 24px;
  min-height: 120px;
}

.datasetDetailHeaderContent {
  padding: 0px 0 5px 30px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  font-family: Lato;
  width: 80%;
  display: -webkit-inline-box;
}

.datasetDetailHeaderContent #poc_email {
  position:absolute;
  top: -700px;
}

.datasetDetailHeaderText {
  /* padding: 0px 0 5px 30px; */
  color: #7bcbd9;
  font-size: 17px;
  font-family: Lato;
  width: 100%;
  display: flex;
  /* text-decoration: none; */
}

.datasetDetailHeaderLink {
  color: #7bcbd9;
  /* text-decoration-line: underline; */
}

.datasetDetailHeaderLink:hover {
  color: #7bcbd9;
  /* font-size: 21px; */
}

.datasetIcon {
  width: 130px;
  position: absolute;
  right: 30px;
  top: 30px;
  border: 1px solid #FFBF17;
  padding: 10px;
  height: 106px;
}

.datasetTypeButton {
  /* width: 100%; */
  /* font-size: 15px; */
  background-color: white;
  margin-left: 88%;
  /* margin-bottom: -50px; */
  margin-top: 50px;
  border: 2px solid gold;
  border-radius: 20px 20px 20px 20px;
  /* margin-top: 10px; */
}

.datasetspace {
  /* padding: 10px; */
  /* padding: 0.25rem 9999rem; */
  border-bottom: 1px solid #BFD3E1;
  margin: 0 -9999rem;
}

.aboutContentContainer {
  padding: 10px 10px 60px 10px;
  border: 1px solid #BFD3E1;
  border-top: none;
  /* border-bottom: 1px solid gray; */
  /* background-color: rgb(248, 248, 248); */
  width: 1220px;
  margin-left: -10px;
  display: grid;
}

.aboutDatasetContainer {
  padding: 5px 0 10px 20px;
  background-color: rgb(248, 248, 248);
  /* border-top: 1px solid gray; */
  /* border-bottom: 1px solid gray; */
}

.aboutDatasetLabel {
  padding: 5px 0 10px 10px;
  /* color: rgb(180, 180, 180); */
  /* color: #99AFBE; */
  color: #4f85aa;
  font-size: 26px;
  font-family: Inter;
  /* font-weight: bold; */
  text-transform: uppercase;
  /* border-bottom: 2px solid rgb(163, 163, 163); */
  border-bottom: 3px solid #BFD3E1;
  inline-size: 1160px;
}

.aboutDatasetContent {
  padding: 23px 0px 23px 10px;
  font-family: Lato;
  border-bottom: 3px solid #BFD3E1;
  inline-size: 1160px;
}

.hiddenElement {
  position: absolute;
  opacity: 0;
}

.coreDataContainer {
  padding: 0px 30px 10px 20px;
  /* margin-top: 10px; */
  width: 48%;
  /* height: 300px; */
  /* height: auto; */
  border-right: 1px solid #BFD3E1;
  /* display: flex; */
  /* display: inline-block; */
  /* position: relative; */
  display: inline-table;
}

.coreDataLabel {
  position: relative;
  bottom: 8px;
  margin-bottom: -10px;
  padding: 20px 0 10px 0px;
  color: #004187;
  font-size: 23px;
  font-weight: 900;
  letter-spacing: 1px;
  font-family: Lato;
  border-bottom: 1px solid #004187;
  width: 100%;
}

.additionalDataContainer {
  padding: 0px 0 10px 31px;
  margin-left: -1px;
  width: 48%;
  border-left: 1px solid #BFD3E1;
  display: inline-table;
}

.additionalDataContainer #dbgap_study_identifier {
  position: absolute;
  top: -100px;
}

.additionalDataContainer #published_in {
  position: absolute;
  top: -100px;
}

.additionalDataLabel {
  position: relative;
  bottom: 8px;
  margin-bottom: -10px;
  padding: 20px 0 10px 0px;
  color: #004187;
  font-size: 23px;
  font-weight: 900;
  font-family: Lato;
  border-bottom: 1px solid #004187;
  /* width: 95%; */
  width: 540px;
}

.dataElementLabel {
  padding: 20px 0 0px 0px;
  color: #b98e2f;
  /* color: goldenrod; */
  /* color: #25b39a; */
  font-size: 16px;
  font-family: Lato;
  text-transform: uppercase;
}

.dataElementContent {
  /* font-size: 16px; */
  font-family: Lato;
  /* height: 100px; */
  max-height: 200px;
  max-width: 540px;
  overflow: auto;
  /* inline-size: 540px; */
  /* max-inline-size: 500px; */
  /* line-break: auto; */
}

.grantInfoContainer {
  margin: 0px 0px -36px 0px;
}

.grantIDDataContainer {
  /* margin: 0px 10px 0px -5px; */
  margin: 0px 0px 0px -8px;
  color: #000000;
  /* width: 30%; */
  display: inline-table;
}

.grantNameDataContainer {
  margin: 0px 0 0px 10px;
  color: #000000;
  /* width: 70%; */
  display: inline-table;
}

.dataElementContentGrantName::-webkit-scrollbar {
  height: 10px;
}

.dataElementContentPublished {
  color: #00a272;
  font-family: Lato;
  font-weight: normal;
  max-height: 200px;
  max-width: 540px;
  overflow: auto;
  word-break: break-all;
}

.dataElementContentPublished:hover {
  color: #00a272;
}

.dataElementContentAllCaps {
  /* font-size: 16px; */
  font-family: Lato;
  text-transform: uppercase;
  max-height: 200px;
  max-width: 540px;
  overflow: auto;
}

.additionalDataExtraSpace {
  margin-top: -10px;
}

.additionalDataLinks {
  margin-top: 10px;
  color: #00a272;
  /* color: black; */
  text-transform: none;
  max-width: 95%;
  word-break: break-all;
}

.additionalDataLinks:hover {
  color: #00a272;
}

.additionalDataContent {
  /* margin-top: 10px; */
  color: black;
  /* font-family: Lato; */
  text-transform: none;
  /* word-wrap: break-word; */
  /* overflow-wrap: break-word; */
  max-width: 95%;
  /* word-break: break-all; */
  /* line-break: anywhere; */
  /* max-inline-size: 80%; */
  /* overflow-inline: 200px; */
}

